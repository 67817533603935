body {
  margin: 0;
  text-align: center;
  /* font-family: 'Livvic', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}
h1 {
  margin: 0;
  color: white;
  padding-top: 100px;
}
h2 {
  color: lightgray;
}
